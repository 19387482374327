<template>
  <div class="terms-page">
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
useHead({
  title: 'HR OnBoard - 利用規約',
})
</script>

<style lang="scss">
.terms-page {
  display: flex;

  > .body {
    width: 100vw;
    min-height: 100vh;
  }
}
</style>
